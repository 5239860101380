import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const inventories = [
  { id: 1, name: "Common Sword 1", description: "a sword 1 for a common warrior", image: "https://i.postimg.cc/t4bhJj42/sword.webp" },
  { id: 2, name: "Common Sword 2", description: "a sword 2 for a common warrior", image: "https://i.postimg.cc/t4bhJj42/sword.webp" },
  { id: 3, name: "Common Sword 3", description: "a sword 3 for a common warrior", image: "https://i.postimg.cc/t4bhJj42/sword.webp" },
];

function Inventory() {
  const [selectedInventory, setSelectedInventory] = useState();

  useEffect(() => {
    if (inventories.length > 0) {
      setSelectedInventory(inventories[0]);
    }
  }, []);

  return (
    <div
      className="relative ml-8 h-full bg-cover bg-no-repeat"
      style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/inventory.webp')` }}
    >
      <div className="w-56 h-2/3 text-sm font-bold ml-[4.5rem] mt-32">
        {selectedInventory ? (
          <div className="h-full text-center flex flex-col justify-between">
            <div>
              <img src={selectedInventory?.image} alt={selectedInventory?.name} className="w-5/6 mx-auto mb-6 mt-8" />
              <h4 className="font-bold text-xl text-black">{selectedInventory?.name}</h4>
              <p>{selectedInventory?.description}</p>
            </div>
            <div className="flex justify-between items-center">
              <a href="#">
                <img src="https://imagedelivery.net/E-VnZk4fwouzlzwX_qz4fg/a7584af9-32cc-4a68-4bda-64ead5a07300/public" alt="ME" className="w-10 rounded-md" />
              </a>
              <div className="text-black">FP: 5 SOL</div>
            </div>
          </div>
        ) : (
          <>
            <img src="https://i.postimg.cc/vB7xFZKP/nodata.webp" alt="No Data" className="mx-auto mb-4 mt-8" />
            <div className="text-center font-cursive text-[#141F58]">
              <p>You have no item!</p>
              <p>
                Join{" "}
                <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`} className="hover:text-amber-800">
                  Battles
                </Link>{" "}
                to get some!
              </p>
            </div>
          </>
        )}
      </div>
      <div className="absolute right-16 pr-5 top-48 bottom-10 w-56 overflow-auto scrollbar-thin scrollbar-thumb-[#66b7ff] scrollbar-track-[#4191D5] scrollbar-rounded-lg">
        <div className="grid grid-cols-2 gap-6">
          {inventories.length > 0 &&
            inventories.map((item) => (
              <div
                className={`w-full aspect-square rounded-md bg-[#4191D5] cursor-pointer hover:p-1 transition-all ${item.id == selectedInventory?.id && "p-1"}`}
                onClick={() => setSelectedInventory(item)}
              >
                <img src={item.image} alt={item.name} className="w-full aspect-square rounded-md bg-white" />
              </div>
            ))}
          <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
          <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
          <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
          <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
          <div className="w-full aspect-square rounded-md bg-[#4191D5] cursor-not-allowed"></div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;

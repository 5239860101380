import LayoutWhitelist from "../layouts/LayoutWhitelist";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import animationData from "../lotties/envelope-open";
import { useEffect, useRef, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import LoadingSpinner from "../partials/LoadingSpinner";
import { generateAuthorizationHeader } from "../utils/oauth-util";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import ConnectWallet from "../partials/whitelist/ConnectWallet";
import WalletAddress from "../partials/whitelist/WalletAddress";
import { Icon } from "@iconify/react";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import { useWeb3Auth } from "../web3/solana/auth";
const queryString = require("query-string");

function Whitelist() {
  const { doWeb3Auth } = useWeb3Auth();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const navigate = useNavigate();
  const lottieRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const wallet = useWallet();
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReveal, setIsReveal] = useState(false);
  const [isSocialConnect, setIsSocialConnect] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [usedSlots, setUsedSlots] = useState(0);
  const [allowedSlots, setAllowedSlots] = useState(0);
  const [oauthTokenParam, setOauthTokenParam] = useState("");
  const [isFollowingKhuga, setIsFollowingKhuga] = useState(undefined);
  const [startReveal, setStartReveal] = useState(false);
  const [revealAnimated, setRevealAnimated] = useState(0);
  const [tweetPosted, setTweetPosted] = useState(false);
  const [twitterData, setTwitterData] = useState();
  const [discordAuthCode, setDiscordAuthCode] = useState("");
  const inputReferralRef = useRef('');
  const canvasRef = useRef(null);

  const handleRemoveReferral = () => {
    setReferralCode("");
    localStorage.removeItem('ref_code');
  }

  const handleDiscordAuth = async () => {
    // Generate state and save in localStorage
    const state = `DISCORD${Math.random().toString(36).slice(2, 7)}`;
    localStorage.setItem("discord_state", state);
    // Redirect to authorizatin
    window.location.href = (`https://discord.com/oauth2/authorize?response_type=code&client_id=1046094994529853530&scope=identify%20guilds.members.read&state=${state}&redirect_uri=${encodeURIComponent("https://khuga-solana.pages.dev/whitelist")}&prompt=consent`);
  }

  const handleDiscordCallback = async () => {
    const callbackParams = queryString.parse(location.search);
    setSearchParams({});
    console.log(callbackParams);

    // validate state
    const state = localStorage.getItem("discord_state");
    if (state !== callbackParams.state) {
      localStorage.removeItem("discord_state");
      return;
    }

    setDiscordAuthCode(callbackParams.code);
  }

  const doDiscordWhitelistFlow = async () => {
    setIsLoading(true);
    let discordRef;
    try {
      discordRef = await axios.post(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/discord-referral`, {
        code: discordAuthCode
      });
    } catch (e) {
      if (e.response?.data?.message) {
        toast.error("Error: Unauthorized Account");
      } else {
        toast.error("Unknown Error!");
      }

      localStorage.removeItem("discord_state");
      setDiscordAuthCode("");
      setIsLoading(false);
      return;
    }

    localStorage.setItem("ref_code", discordRef?.data?.data?.ref_code);
    setReferralCode(discordRef?.data?.data?.ref_code);
    setUsedSlots(discordRef?.data?.data?.allowed_slots - discordRef?.data?.data?.slots_left);
    setAllowedSlots(discordRef?.data?.data?.allowed_slots);
    setIsLoading(false);

    return;
  }

  const handleTwitterLogin = async () => {
    setIsLoading(true);
    const oauthSignature = generateAuthorizationHeader(
      "POST",
      "https://api.twitter.com/oauth/request_token",
      "FeywelAVN2KyWXv0PGUCquWp1",
      "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
      "", "", // Initially no auth token
      {
        oauth_callback: encodeURIComponent("https://khuga-solana.pages.dev/whitelist")
        // oauth_callback: encodeURIComponent("https://khuga.io/whitelist")
      }
    );

    let tempAuthToken;
    try {
      tempAuthToken = await axios.post(`https://cors.khuga.io/https://api.twitter.com/oauth/request_token`, {}, {
        headers: {
          Authorization: `${oauthSignature}`
        }
      })

    } catch (err) {
      toast.error("Error Occured!");
      return;
    }

    console.log(tempAuthToken);

    setOauthTokenParam(tempAuthToken?.data);
    localStorage.setItem("oauthParam", tempAuthToken?.data);

    window.location.href = (`https://api.twitter.com/oauth/authorize?${tempAuthToken?.data}`);
  }

  // const handleFollowKhuga = async () => {
    // const oauthToken = localStorage.getItem("oauth_token");
    // const oauthTokenSecret = localStorage.getItem("oauth_token_secret");

    // let oauthFollowSignature = generateAuthorizationHeader(
    //   "POST",
    //   "https://api.twitter.com/1.1/friendships/create.json",
    //   "FeywelAVN2KyWXv0PGUCquWp1",
    //   "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
    //   oauthToken, oauthTokenSecret,
    //   {
    //     user_id: "1514628931290558468",
    //     follow: "true"
    //   }
    // );

    // let follow;
    // try {
    //   follow = await axios.post("https://cors.khuga.io/https://api.twitter.com/1.1/friendships/create.json?follow=true&user_id=1514628931290558468", {}, {
    //     headers: {
    //       Authorization: oauthFollowSignature,
    //     }
    //   });
    // } catch (err) {
    //   console.log("Follow error");
    //   setIsLoading(false);
    //   toast.error("Error Occured!");
    //   return;
    // };

    // setIsLoading(false);
    // setIsFollowingKhuga(true);
  // }

  const doTwitterOAuth = async () => {
    const searchParams = queryString.parse(location.search);
    if (!searchParams?.oauth_token) {
      setSearchParams({});
      return;
    }
    const oauthSignature = generateAuthorizationHeader(
      "POST",
      "https://api.twitter.com/oauth/access_token",
      "FeywelAVN2KyWXv0PGUCquWp1",
      "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
      "", "", // Initially no auth token
      searchParams
    );
    const oauthToken = await axios.post(`https://cors.khuga.io/https://api.twitter.com/oauth/access_token${location.search}`, {}, {
      headers: {
        Authorization: oauthSignature
      }
    });
    console.log("Final Auth");
    const oauthTokenData = queryString.parse(oauthToken.data);

    Object.keys(oauthTokenData).sort().forEach(function (key) {
      localStorage.setItem(key.trim(), oauthTokenData[key].trim());
    });

    setIsSocialConnect(true);
  }

  const checkKhugaFriendShipStatus = async () => {
    setIsLoading(true);
    const oauthToken = localStorage.getItem("oauth_token");
    const oauthTokenSecret = localStorage.getItem("oauth_token_secret");

    await internalAPIClient
      .post(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-confirm-follow`, {
        twitter_access_token: oauthToken,
        twitter_token_secret: oauthTokenSecret,
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          console.log(res);
          setIsFollowingKhuga(true);
          setTwitterData({
            id: res?.data?.data?.id_str,
            media: res?.data?.data?.media_url_https,
          });
        } else {
          setIsFollowingKhuga(false);
          console.log(res?.data?.message);
          toast.error("Error Occured!");
        }
      })
      .catch((err) => {
        setIsFollowingKhuga(false);
        console.log(err);
        toast.error("Error Occured!");
      });

    setIsLoading(false);

    // const oauthToken = localStorage.getItem("oauth_token");
    // const oauthTokenSecret = localStorage.getItem("oauth_token_secret");

    // let oauthSignature = generateAuthorizationHeader(
    //   "GET",
    //   "https://api.twitter.com/1.1/friendships/lookup.json",
    //   "FeywelAVN2KyWXv0PGUCquWp1",
    //   "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
    //   oauthToken, oauthTokenSecret,
    //   {
    //     "user_id": "1514628931290558468"
    //   }
    // );

    // let friendshipStatus;
    // try {
    //   friendshipStatus = await axios.get(`https://cors.khuga.io/https://api.twitter.com/1.1/friendships/lookup.json?user_id=1514628931290558468`, {
    //     headers: {
    //       Authorization: oauthSignature
    //     }
    //   });
    // } catch (err) {
    //   setIsLoading(false);
    //   toast.error("Error Occured!");
    //   return;
    // };

    // setIsLoading(false);
    // setIsFollowingKhuga(false);
    // if (friendshipStatus.data[0]?.connections?.includes("following")) {
    //   setIsFollowingKhuga(true);
    // }
  }

  const handleTweet = async () => {
    setIsLoading(true);

    // Upload Image
    const imageFormData = new FormData();
    imageFormData.append("media_data", canvasRef.current.toDataURL().split(",")[1]);
    const oauthToken = localStorage.getItem("oauth_token");
    const oauthTokenSecret = localStorage.getItem("oauth_token_secret");

    let oauthSignature = generateAuthorizationHeader(
      "POST",
      "https://upload.twitter.com/1.1/media/upload.json",
      "FeywelAVN2KyWXv0PGUCquWp1",
      "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
      oauthToken, oauthTokenSecret,
      {
        "media_category": "tweet_image"
      }
    );

    let imagePost;
    try {
      imagePost = await axios.post(`https://cors.khuga.io/https://upload.twitter.com/1.1/media/upload.json?media_category=tweet_image`, imageFormData, {
        headers: {
          Authorization: oauthSignature,
          "content-type": "multipart/form-data"
        }
      });
    } catch (err) {
      setIsLoading(false);
      toast.error("Error Occured!");
    };

    let authenticityToken;
    try {
      authenticityToken = await axios.post(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-register?ref_code=${referralCode}`, {
        twitter_user_id: localStorage.getItem("user_id"),
        wallet_address: wallet?.publicKey.toString(),
      });
    } catch (err) {
      setIsLoading(false);
      toast.error("Error Occured! " + err.response?.data?.message);
      return;
    };

    const postToMake = {
      "status": `With this, I officially join @KhugaNFT squad for the upcoming battle! #KhugaNFT #KhugaVerse\n\nAuthenticity Token: ${authenticityToken.data.data.token}`,
      "media_ids": imagePost.data.media_id_string
    };

    oauthSignature = generateAuthorizationHeader(
      "POST",
      "https://api.twitter.com/1.1/statuses/update.json",
      "FeywelAVN2KyWXv0PGUCquWp1",
      "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
      oauthToken, oauthTokenSecret,
      {
        ...postToMake,
        status: encodeURIComponent(postToMake.status).replace(/!/g, "%21")
      }
    );

    let tweet;
    try {
      tweet = await axios.post(`https://cors.khuga.io/https://api.twitter.com/1.1/statuses/update.json?status=${encodeURIComponent(postToMake.status).replace(/!/g, "%21")}&media_ids=${postToMake.media_ids}`, {}, {
        headers: {
          Authorization: oauthSignature,
        }
      });
    } catch (err) {
      setIsLoading(false);
      toast.error("Error Occured!");
      return;
    };

    let tweetConfirmation;
    try {
      tweetConfirmation = await axios.patch(
        `${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-register/${authenticityToken.data.data.whitelist_id}?ref_code=${referralCode}`,
        {
          twitter_post_id: tweet.data.id_str,
        }
      );
    } catch (err) {
      toast.error(`Deleteing tweet: ${tweet.data.id}`);
      console.log(err);
      // Delete tweet
      oauthSignature = generateAuthorizationHeader(
        "DELETE",
        `https://api.twitter.com/2/tweets/${tweet.data.id_str}`,
        "FeywelAVN2KyWXv0PGUCquWp1",
        "3u04bFuSB9LxCoV2IoU29tNQmY5kt1GUCR1sbBmY2tRIvZhY38",
        oauthToken, oauthTokenSecret,
        {}
      );

      await axios.delete(`https://cors.khuga.io/https://api.twitter.com/2/tweets/${tweet.data.id_str}`, {
        headers: {
          Authorization: oauthSignature,
        }
      });

      // UI Handler
      setIsLoading(false);
      toast.error("Error Occured");

      await (() => new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log("Done waiting");
          resolve()
        }, 1000)
      }));

      // Cleanup storage
      localStorage.removeItem("oauth_token");
      localStorage.removeItem("oauth_token_secret");
      handleRemoveReferral();
      setIsSocialConnect(false);
      navigate("/whitelist");
      return;
    }

    // UI Handler
    setIsLoading(false);
    setTweetPosted(true);
    toast.success("Tweet successfully posted!");
  }

  useEffect(async () => {
    if (wallet.connecting) {
      setIsLoading(true);
    } else if (!wallet.connecting && !wallet.connected) {
      setIsLoading(false);
    } else if (!wallet.connecting && !wallet.disconnecting && wallet.connected) {
      // ------------------------
      // Web3
      // ------------------------
      const auth = localStorage.getItem("x-auth-khuga");
      if (auth === null || auth === undefined) {
        // Get Nonce
        setIsLoading(true);
        console.log("Initiating Web3 Authentication");
        const nonce = await internalAPIClient.get(`/web3/auth?public_key=${wallet.publicKey}`).catch((e) => {
          setIsLoading(false);
          wallet.disconnect();
          return toast.error("Authentication Error: " + e);
        });
  
        console.log(`Nonce is: ${nonce?.data?.data?.nonce}`);
  
        if (nonce?.data?.data?.nonce !== undefined) {
          // Sign Message
          setIsLoading(true);
          console.log("Please Sign the Authentication with Wallet");
          try {
            const signature = await doWeb3Auth(wallet.publicKey, nonce?.data?.data?.nonce);
  
            // Verify
            setIsLoading(true);
            console.log("Verifying Message Signing");
            const verify = await internalAPIClient
              .post("/web3/verify", {
                public_key: wallet.publicKey,
                signature: signature,
              })
              .catch((e) => {
                setIsLoading(false);
                wallet.disconnect();
                return toast.error("Authentication Error: " + e);
              });
  
            setIsLoading(false);
            console.log(verify);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 500);
          } catch (e) {
            setIsLoading(false);
            wallet.disconnect();
            return toast.error("Authentication Error: " + e);
          }
        }
      }

      setIsLoading(false);

      if (discordAuthCode) {
        await doDiscordWhitelistFlow();
        return;
      }

      // Check whitelist status
      axios
        .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-status/${wallet?.publicKey.toString()}`)
        .then((res) => {
          if (res?.data?.status === "success") {
            setIsWhitelisted(res?.data?.data?.whitelisted);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error("Error occured!");
        });
    }
  }, [wallet, discordAuthCode]);

  useEffect(() => {
    if (isSocialConnect) {
      const canvas = canvasRef.current;
      if (canvas) {
        let myFont = new FontFace("Nanum Pen Script", "url('/images/whitelist/nanum.woff2')");
        myFont.load().then((font) => {
          document.fonts.add(font);
          const ctx = canvas.getContext("2d");
          let img = new Image();
          img.addEventListener("load", () => {
            ctx.drawImage(img, 0, 0);
            ctx.font = "30px 'Nanum Pen Script'";
            ctx.fillText(`${localStorage.getItem("screen_name")},`, 300, 444);
          });
          const letterNumber = Math.floor(Math.random() * 5) + 1;
          img.src = `/images/whitelist/letter-${letterNumber}.webp`;
        });
      }
    }
  }, [isSocialConnect]);

  useEffect(() => {
    if (!isFollowingKhuga) return;
    setTimeout(() => {
      setIsReveal(true);
    }, 2500);
  }, [isFollowingKhuga]);

  useEffect(async () => {
    setIsLoading(true);
    if (referralCode && !localStorage.getItem("discord_state")) {
      await axios.get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-slots?ref_code=${referralCode}`).then((res) => {
        setUsedSlots(res?.data?.data?.used_slots);
        setAllowedSlots(res?.data?.data?.allowed_slots);
      }).catch((err) => {
        toast.error("Referral code is invalid!");
        handleRemoveReferral();
        navigate("/whitelist");
      });
    }
    setIsLoading(false);
  }, [referralCode]);

  useEffect(async () => {
    setIsLoading(true);
    setReferralCode(localStorage.getItem("ref_code"));
    console.log("on mounted");
    if (!location.search) {
      handleRemoveReferral();
      setIsLoading(false);
      return;
    }

    // if is discord callback
    // discord will return code and state param
    const searchParams = queryString.parse(location.search);
    if (searchParams?.code && searchParams?.state?.includes("DISCORD")) {
      handleDiscordCallback();
      return;
    }

    await doTwitterOAuth()
    await checkKhugaFriendShipStatus();
  }, []);

  const handleSubmitReferral = () => {
    if (inputReferralRef.current.value != '') {
      localStorage.removeItem("discord_state");
      setReferralCode(inputReferralRef.current.value);
      localStorage.setItem('ref_code', inputReferralRef.current.value);
    } else {
      toast.error("Please input referral code!");
    }
  }

  const handleDownloadCert = () => {
    var canvas = document.getElementById("canvas");
    var url = canvas.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "khuga-whitelist.png";
    link.href = url;
    link.click();
  };

  return (
    <LayoutWhitelist>
      {(isLoading || (wallet?.connected && isWhitelisted === undefined) || (isSocialConnect && isFollowingKhuga === undefined)) && <LoadingSpinner />}
      <canvas ref={canvasRef} id="canvas" width="1080" height="1080" className="w-full mx-auto mb-4 hidden"></canvas>

      <div className="w-full h-screen">
        {wallet?.connected ? (
          <div className="relative flex justify-center items-center h-full z-10 text-center">
            <div className="container">
              <WalletAddress />

              {isWhitelisted ? (
                <>
                  <Icon icon="bi:file-earmark-check" className="w-16 h-16 mx-auto mb-8 text-primary-800 animate-pulse" />
                  <h1 className="font-cursive text-2xl">Your wallet address has been registered</h1>
                </>
              ) : (
                <>
                  {/* Show when the user has not entered the referral code */}
                  {!referralCode && !isSocialConnect && (
                    <div>
                      <h4 className="font-cursive text-black text-xl font-bold mb-4">Enter Referral Code</h4>
                      <div className="flex items-center justify-center space-x-2">
                        <div
                          className="w-64 h-16 bg-contain bg-no-repeat bg-center"
                          style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/whitelist/referral-input.webp')` }}
                        >
                          <input
                            ref={inputReferralRef}
                            type="text"
                            className="text-xl w-64 p-3 font-bold text-center bg-transparent border-none focus:ring-0 focus:outline-none"
                          />
                        </div>
                        <button className="hover:scale-105 active:scale-100 transition-all" onClick={() => handleSubmitReferral()}>
                          <img src={`${process.env.PUBLIC_URL}/images/whitelist/referral-submit.webp`} alt="Sign in Twitter" className="h-16" />
                        </button>
                      </div>
                      <button
                        className="px-4 py-3 inline-flex font-cursive underline text-white mt-12 hover:-translate-y-1 hover:shadow-xl hover:shadow-primary-700/20 transition-all bg-[#5865f2] rounded-md shadow-md border border-black border-b-4"
                        onClick={() => handleDiscordAuth()}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-discord h-6 w-6 mr-2" viewBox="0 0 16 16">
                          <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                        </svg>
                        I've Whitelisted on Discord
                      </button>
                    </div>
                  )}

                  {/* Show when the user has not logged in with twitter */}
                  {referralCode && !isSocialConnect && (
                    <>
                      {allowedSlots > 0 && usedSlots / allowedSlots < 1 ? (
                        <>
                          <div
                            className="w-60 h-20 bg-contain bg-no-repeat bg-center mx-auto mb-10 font-cursive text-xl text-white pt-7"
                            style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/whitelist/bg-slots.webp')` }}
                          >
                            {usedSlots}/{allowedSlots} Slots
                          </div>
                          <h4 className="font-cursive text-black text-xl font-bold">Please connect to your twitter account</h4>
                          <div className="flex items-center justify-center space-x-3 mt-5">
                            <button className="hover:scale-105 active:scale-100 transition-all" onClick={() => handleTwitterLogin()}>
                              <img src={`${process.env.PUBLIC_URL}/images/whitelist/btn-sign-twitter.webp`} alt="Sign in Twitter" className="h-16" />
                            </button>
                          </div>
                        </>
                      ) : (
                        <div>
                          <img src={`${process.env.PUBLIC_URL}/images/whitelist/slot-full.webp`} alt="Full" className="mx-auto h-20" />
                          <button
                            onClick={() => handleRemoveReferral()}
                            className="bg-primary-800 px-6 py-2.5 rounded-md inline-block text-white font-cursive mt-8"
                          >
                            Back
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  {/* Show when the user has logged in with twitter */}
                  {isSocialConnect && (
                    <div className="relative">
                      {!isFollowingKhuga ? (
                        <>
                          <img src="/images/whitelist/envelope.webp" alt="" className="w-96 mx-auto rounded animate-updown" />
                          <div className="">
                            <h4 className="font-cursive text-2xl text-black font-bold mb-4">Follow to unlock!</h4>
                            <div className="flex justify-center items-center space-x-2">
                              <a
                                className="bg-[#1d9cf0] inline-flex items-center space-x-2 px-6 py-3 text-white rounded-full hover:contrast-150"
                                href="https://twitter.com/KhugaNFT"
                                target="_blank"
                              >
                                <Icon icon="fa-brands:twitter" />
                                <span>Follow @KhugaNFT</span>
                              </a>
                              <button
                                className="bg-[#1d9cf0] inline-flex items-center space-x-2 px-6 py-3 text-white rounded-full hover:contrast-150"
                                onClick={checkKhugaFriendShipStatus}
                              >
                                <Icon icon="heroicons-outline:shield-check" className="w-5 h-5" />
                                <span>Confirm Follow</span>
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className={`mt-36 ${!isFollowingKhuga && "blur-sm opacity-50"}`}>
                          <h4 className="font-cursive text-3xl text-black font-bold mb-4">Thank You</h4>
                          <div
                            className="relative w-80 h-80 md:w-96 md:h-96 bg-center bg-cover mx-auto p-6 mb-6"
                            style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/whitelist/frame.webp')` }}
                          >
                            <Transition show={!isReveal} leave="transition-opacity duration-0" leaveFrom="opacity-100" leaveTo="opacity-0">
                              <div className="mt-12">
                                <Lottie
                                  options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: animationData,
                                    rendererSettings: {
                                      preserveAspectRatio: "xMidYMid slice",
                                    },
                                  }}
                                  height={300}
                                  width={300}
                                />
                              </div>
                            </Transition>
                            <div className={`absolute inset-6 ${!isReveal && "hidden"}`}>
                              <motion.div key={isReveal} initial={{ scale: 0 }} animate={{ scale: 1 }}>
                                {/* <img src={canvasRef.current.toDataURL("image/png")} alt="LoA" /> */}
                                <img src={twitterData?.media} alt="LoA" />
                              </motion.div>
                            </div>
                          </div>
                          {isFollowingKhuga && (
                            <>
                              <p className="font-bold text-xl mb-4 text-green-700">Your wallet address has been registered!</p>
                              <div className="flex items-center justify-center space-x-4">
                                <button onClick={handleDownloadCert} className="hover:scale-105 active:scale-100 transition-all">
                                  <img src={`${process.env.PUBLIC_URL}/images/whitelist/btn-download.webp`} alt="Download" className="h-12" />
                                </button>
                                {twitterData?.id && (
                                  <a
                                    href={`https://twitter.com/KhugaNFT/status/${twitterData?.id}`}
                                    className="hover:scale-105 active:scale-100 transition-all"
                                  >
                                    <img src={`${process.env.PUBLIC_URL}/images/whitelist/btn-retweet.webp`} alt="Retweet" className="h-12" />
                                  </a>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <ConnectWallet />
        )}
      </div>
    </LayoutWhitelist>
  );
}

export default Whitelist;

import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CartProvider } from "react-use-cart";
import { UserProvider } from "./context/UserContext";
import { UserCollectionsProvider } from "./context/UserCollectionsContext";
import App from "./App";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

require('@solana/wallet-adapter-react-ui/styles.css');

const wallets = [
  new PhantomWalletAdapter(),
  new GlowWalletAdapter(),
  new SlopeWalletAdapter(),
  new TorusWalletAdapter(),
];

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ConnectionProvider endpoint={process.env.REACT_APP_SOLANA_RPC_HOST}>
        <WalletProvider wallets={wallets} autoConnect>
          <CartProvider>
            <UserProvider>
              <UserCollectionsProvider>
                <App />
              </UserCollectionsProvider>
            </UserProvider>
          </CartProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

import LayoutHome from "../layouts/LayoutHome";

function Roadmap() {
  return (
    <LayoutHome>
      <div id="roadmap" className="relative py-32 bg-white">
        <div className="relative">
          <img src={`${process.env.PUBLIC_URL}/images/bg/title-box.webp`} alt="Menu" className="h-16 md:h-20 mx-auto" />
          <span className="absolute top-0 w-full text-center mt-3 md:mt-4 text-white text-3xl md:text-4xl font-cursive">Roadmap</span>
        </div>

        <div
          className="max-w-6xl mx-auto px-4 pt-28 bg-contain bg-no-repeat bg-none lg:bg-[url('../../public/images/roadmap/dashed.webp')]"
          style={{ backgroundPosition: "center 200px" }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-40 mb-20 lg:mb-0">
            <div>
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-1.webp`} alt="Roadmap 1" className="w-full" />
            </div>
            <div className="ml-5">
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-text-1.webp`} alt="Title" className="h-24 mb-8" />
              <div className="space-y-4">
                <p>
                  In this first roadmap, you can adopt Khuga to be the loyal fighter to protect your kingdom. In total, we will create 5,555 Khuga artworks with
                  a distinctive appearance that is pleasing to be seen and worth to be collecting.
                </p>
              </div>
            </div>
          </div>

          <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-2.webp`} alt="Roadmap 2" className="w-full lg:w-11/12 mx-auto" />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mt-6">
            <div className="border-none lg:border-r-4 border-black pr-12">
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-text-2.webp`} alt="Title" className="w-full lg:w-9/12 ml-auto" />
            </div>
            <div>
              <div className="space-y-4">
                <p>
                  As an appreciation to those who adopt Khuga, we present you Miawhalla. It is a fun platform built exclusively for Khuga adopters. In
                  Miawhalla, you can experience a fun-filled exploration into Khugaverse.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 lg:gap-0 items-center mt-12 mb-12">
            <div className="order-last lg:order-first">
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-text-3.webp`} alt="Title" className="h-24 mb-8" />
              <div className="space-y-4">
                <p>
                  An on-ground event where you can rendezvous with Khuga adopters, stare at Khuga artworks that are pleasing to the eye, play games, witness
                  artist collaboration, win doorprizes, and get a chance to bring home Khuga limited merchandise.
                </p>
              </div>
            </div>
            <div className="order-first lg:order-last col-span-2">
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-3.webp`} alt="Roadmap 3" />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
            <div>
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-4.webp`} alt="Roadmap 4" className="w-full" />
            </div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/images/roadmap/roadmap-text-4.webp`} alt="Title" className="h-56 mx-auto mb-8" />
              <div className="space-y-4">
                <p>
                  We will be glad to give the utmost fun adventure experience to our community that has been supportive and loyal. We are preparing the Grand
                  Battle to maximize your adventure in Khugaverse. We are not going to overpromise, but we are committed to presenting you with surprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <footer className="relative w-full bg-black text-white">
        <img src={`${process.env.PUBLIC_URL}/images/bg/footer.webp`} alt="BG" className="hidden md:block absolute bottom-0 w-full inset-x-0 z-0" />
        <img src={`${process.env.PUBLIC_URL}/images/bg/footer-mobile.webp`} alt="BG" className="block md:hidden absolute bottom-0 w-full inset-x-0 z-0" />
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2022 Khuga NFT</span>
        </div>
      </footer> */}
      <footer className="bg-white text-center py-5 text-sm">©2022 Khuga NFT</footer>
    </LayoutHome>
  );
}

export default Roadmap;

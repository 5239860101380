import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";

import "./css/main.css";

// Import pages
import Auth from "./pages/Auth";
import LandingPage from "./pages/LandingPage";
import Gallery from "./pages/Gallery";
import ConnectWallet from "./pages/ConnectWallet";
import Roadmap from "./pages/Roadmap";
import About from "./pages/About";
import { useAuthenticatedHttpClient } from "./utils/authenticated-api-call";
import routes from "./routes";
import MIUHolder from "./pages/MIUHolder";
import AdminAuth from "./pages/Admin/AdminAuth";
import { UserContext } from "./context/UserContext";
import DiscordCallback from "./pages/DiscordCallback";
import NotFoundPage from "./pages/NotFoundPage";
import Whitelist from "./pages/Whitelist";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path={`${process.env.PUBLIC_URL}/`} element={<LandingPage />} />
        <Route exact path={`${process.env.PUBLIC_URL}/connect`} element={<ConnectWallet />} />
        <Route exact path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
        <Route exact path={`${process.env.PUBLIC_URL}/roadmap`} element={<Roadmap />} />
        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} element={<Gallery />} />
        <Route exact path={`${process.env.PUBLIC_URL}/miu`} element={<MIUHolder />} />
        <Route exact path={`${process.env.PUBLIC_URL}/whitelist`} element={<Whitelist />} />
        <Route exact path={`${process.env.PUBLIC_URL}/auth`} element={<Auth />} />
        <Route exact path={`${process.env.PUBLIC_URL}/admin`} element={<AdminAuth />} />
        <Route exact path={`${process.env.PUBLIC_URL}/api/discord/callback`} element={<DiscordCallback />} />
        {routes.map((item) => (
          <Route
            key={item.key}
            exact
            path={`${process.env.PUBLIC_URL}${item.route}`}
            element={<RequireAuth roles={item.roles}>{item.component}</RequireAuth>}
          />
        ))}
        <Route exact path="/*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;

function RequireAuth({ roles, children }) {
  const navigate = useNavigate();
  const { disconnect } = useWallet();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [currentUser] = useContext(UserContext);

  useEffect(async () => {
    // const beacon = await internalAPIClient.get(`/auth/beacon`).catch(async (e) => {
    //   await disconnect();
    //   localStorage.removeItem("x-auth-khuga");
    //   return navigate(`${process.env.PUBLIC_URL}/auth`);
    // });

    // if (beacon?.data?.status !== "success") {
    //   await disconnect();
    //   localStorage.removeItem("x-auth-khuga");
    //   return navigate(`${process.env.PUBLIC_URL}/auth`);
    // }

    if (currentUser === null) {
      return navigate(`${process.env.PUBLIC_URL}/auth`);
    } else if (currentUser !== null && currentUser !== undefined) {
      const hasAccess = roles.includes(currentUser?.roles[0]);
      if (!hasAccess) {
        return navigate(`${process.env.PUBLIC_URL}/home`);
      }
    }
  }, [currentUser]);

  return children;
}

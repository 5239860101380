import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { isMobile } from "react-device-detect";

export default function ConnectWallet() {
  return (
    <div className="relative flex justify-center items-center h-full z-10">
      <div className="container">
        <div className="max-w-2xl mx-auto">
          <div className="bg-center bg-cover p-11 mb-12" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/whitelist/bg-disclaimer.webp')` }}>
            <h1 className="font-cursive text-xl text-black text-center mb-3">
              Step-by-step instructions
              <br />
              for Whitelist registration
            </h1>
            <ol className="list-outside list-decimal font-semibold text-black">
              <li>Always ensure you are on khuga.io.</li>
              <li>
                Connect your Solana wallet.
                <h6 className="font-bold">This registration will not result in any transactions.</h6>
              </li>
              <li>Enter the referral code.</li>
              <li>
                Connect your Twitter account; you must follow @khugaNFT.
                <h6 className="font-bold">A unique token ID will be included in the tweet as proof of claim.</h6>
              </li>
              <li>Tweet your LoA to claim it.</li>
              <li>Your wallet has now been submitted to our database.</li>
            </ol>
          </div>
          <h2 className="mb-8 font-cursive text-2xl text-black text-center">Sign in with your wallet to continue</h2>
          {isMobile && window.solana === undefined ? (
            <a
              href={`https://phantom.app/ul/browse/${encodeURIComponent("https://khuga.io/whitelist")}?ref=${encodeURIComponent(
                "https://khuga.io"
              )}`}
              className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all"
            >
              <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
              <span className="absolute top-0 w-full text-center mt-6 text-white font-cursive text-lg">Open in Phantom App</span>
            </a>
          ) : (
            <WalletModalProvider>
              <div className="flex justify-center items-center">
                <WalletMultiButton className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all !p-0">
                  <img src={`${process.env.PUBLIC_URL}/images/button/connect-wallet.webp`} alt="Menu" className="h-20" />
                  <span className="absolute top-0 w-full text-center mt-2 text-white font-cursive text-lg">Connect With Your Wallet</span>
                </WalletMultiButton>
              </div>
            </WalletModalProvider>
          )}
        </div>
      </div>
    </div>
  );
}
